<template>
  <input
      v-on:keyup.enter="updateActualCost"
      v-model="cost"
      :class="response_status"
      class="form-control form-control-sm w-75 m-auto"
      type="number"
      placeholder="Agreed rate"
  >
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  emits: ['update'],
  name: "EmptyActualCost",
  data() {
    return {
      response_status: '',
    }
  },
  props: {
    additional_cost: {
      type: Number,
    },
    id: {
      type: Number,
    },
  },
  computed: {
    cost: {
      get() {
        return parseFloat(this.additional_cost).toFixed(2)
      }
    }
  },
  methods: {
    async updateActualCost(event) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        await axios.put(`/wagon_empty_order/expanse/update/${this.id}/`, {
          additional_cost: event.target.value
        })
        this.$emit('update')
        await Toast.fire({
          icon: 'success',
          title: 'Additional cost updated',
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
    }
  }
}
</script>

<style scoped>

</style>