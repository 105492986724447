<template>
  <DeleteConfirmation
      id="DeleteEmptyWagonModal"
      header="Delete Empty Wagon"
      :confirmationPlaceholder="data.container ? data.container : data.id"
      @onDelete="onDeleteConfirmed"
  >
  </DeleteConfirmation>
</template>

<script>
import DeleteConfirmation from "@/components/forms/DeleteConfirmation.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  emits: ['update'],
  name: "wagon_delete",
  props: {
    wagon: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    async onDeleteConfirmed() {
      try {
        await axios.delete(`/wagon_empty_order/delete_row/${this.data.id}/`)
        document.querySelector('#DeleteEmptyWagonModal .btn-close').click()
        this.$emit('update')
        await Swal.fire({
          icon: 'success',
          title: 'Empty wagon deleted successfully',
          showConfirmButton: false,
          timer: 2000,
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
  computed: {
    data() {
      return this.wagon || {}
    }
  },
  components: {
    DeleteConfirmation
  }
}
</script>

<style scoped>

</style>