<template>
  <input v-if="wagon_computed === null" v-model="new_wagon_name" class="form-control form-control-sm" type="text"
         placeholder="Wagon number" v-on:keyup.enter="updateExpense(id, new_wagon_name)" :class="response_status">

  <input v-else v-model="wagon_computed.name" class="form-control form-control-sm" type="text"
         placeholder="Wagon number"
         v-on:keyup.enter="updateExpense(id, wagon_computed.name)"
         :class="response_status">

  <div v-if="wagon_computed !== null && code !== ''" class="y position-relative">
    <span
        class="order-connected-code position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"> {{
        code
      }}
    </span>
  </div>

</template>

<script>
import Swal from "sweetalert2";
import axios from "axios"

export default {
  name: "wagonInput",
  data() {
    return {
      new_wagon_name: '',
      response_status: '',
    }
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    wagon: {
      type: Object,
    },
    code: {
      type: String,
    },
  },
  computed: {
    wagon_computed: {
      get() {
        return this.wagon
      }
    },
  },
  methods: {
    async updateExpense(id, newValue) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        await axios.put(`/wagon_empty_order/expanse/update/${id}/`, {
          wagon_name: newValue,
          wagon_empty_order_number: this.$route.params.id
        })
        this.response_status = 'border-success'
        await Toast.fire({
          icon: 'success',
          title: 'Wagon name updated',
        })
      } catch {
        this.response_status = 'border-danger'
        await Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
    }
  },
  watch: {
    "$route.query.wagon": {
      handler: function () {
        if (!this.$route.query.wagon) return ''
        if (!this.wagon) return ''
        let wagon = this.wagon.name.toString().trim().toLowerCase()
        let search = this.$route.query.wagon.toString().trim().toLowerCase()

        if (wagon.includes(search)) {
          this.response_status = 'border-success border-3'
        } else {
          this.response_status = ''
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>