<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "AddRow",
  emits: ['update'],
  props: {
    order_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      quantity: 1,
      is_loading: false
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    async addRow() {
      try {
        if (this.order_id) {
          await axios.post(`/wagon_empty_order/add_row/${this.order_id}/`, {
            quantity: this.quantity
          })
          this.$emit('update')
          await Swal.fire({
            icon: 'success',
            title: 'Successfully added a new row'
          })
          this.showModal = false
        } else {
          await Swal.fire({
            icon: 'warning',
            title: 'Cannot add row to an order without an ID'
          })
        }
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Something went wrong'
        })
      }
    },
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Add new row" hide-footer centered>
    <form @submit.prevent="addRow">
      <div class="mb-3">
        <label for="quantity">Quantity:</label>
        <input class="form-control" type="number" v-model="quantity" required placeholder="Enter quantity">
      </div>
      <div>
        <b-button class="my-0 w-100" variant="success" :disabled="quantity <=0 || is_loading" type="submit">
          Add
          <b-spinner v-if="is_loading" class="btn-loader-item" small type="grow"/>
        </b-button>
      </div>
    </form>
  </b-modal>

  <b-button class="my-0" v-if="!is_loading" variant="outline-success" size="sm" @click="openModal()">+ Add rows
  </b-button>
</template>

<style scoped>

</style>