<template>
  <div style="min-width: 100px">

    <div class="y position-relative w-75 mx-auto">

      <input v-on:keyup.enter="updateActualCost(cost.id, cost.actual_cost)"
             v-model="cost.actual_cost" :class="response_status"
             class="form-control form-control-sm"
             type="number" placeholder="Agreed rate">

      <span v-if="cost.code"
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
            :class="{
        'bg-success': cost.code.status === 'completed',
        'bg-warning': cost.code.status === 'checking',
        'bg-secondary': cost.code.status === 'used',
        'bg-danger': cost.code.status === 'canceled',
            }"
      >
        <router-link class="text-light" :to="{name: 'codes_list', query: {
          number: cost.code.number,
          order: this.$route.params.id || 2
        }}">
          {{ cost.code.number }}
        </router-link>
      </span>

      <span v-if="act" class="position-absolute top-0 start-0 translate-middle">
        <VTooltip>
         <router-link
             @click="localStorage.setItem(`contract_${act.contract_slug}`)"
             :to="{name: 'counterparty_profile_contracts_acts',
             params: {
               slug: act.company_slug,
               contract_slug: act.contract_slug,
             },
             query: {
                act: act.slug,
             }
         }"
         >
            <i class="mdi mdi-circle-medium text-info fs-24 align-middle"></i>
         </router-link>
          <template v-slot:popper>
            Act: <span class="fw-bold ms-1">{{ act.name }}</span>
          </template>
        </VTooltip>
      </span>

    </div>

  </div>

</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  emits: ['update'],
  name: "EmptyActualCost",
  data() {
    return {
      response_status: '',
    }
  },
  props: {
    actual_cost: {
      type: Object,
    },
    act: {
      type: Object || null,
    }
  },
  computed: {
    cost: {
      get() {
        return this.actual_cost
      }
    }
  },
  methods: {
    async updateActualCost(id, cost) {

      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        await axios.put(`/wagon_empty_order/expanse/actual_cost/update/${id}/`, {
          actual_cost: cost
        })
        this.$emit('update')
        this.response_status = 'border-success'
        await Toast.fire({
          icon: 'success',
          title: 'Actual cost updated',
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: "Could not update Actual cost",
        })
      }
    }
  }
}
</script>

<style scoped>

</style>